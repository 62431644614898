<template>
  <div class="loading">
    <div class="content">
      <img class="image-loading" src="../assets/imgs/loading.svg" alt=""/>
      <!-- <div class="message">More powerful Arvin is one step away...</div> -->
    </div>
  </div>
</template>

<script>

</script>

<style lang="less" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
}

.content {
  width: 665px;
  text-align: center;
  .image-loading {
    width: 40px;
    height: 40px;

    animation: loading 1s linear infinite;
  }

  .message {
    margin-top: 48px;
    font-weight: 600;
    color: white;
    font-size: 20px;
  }

  @media screen and (max-width: 696px) {
    width: calc(100% - 32px);
    margin: 0 16px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 545px) {
  width: calc(100% - 32px);
  margin: 0 16px;
}
</style>
